import React from 'react';

import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { Col, Row, Spin, Modal } from 'antd';
import LogoMain from '../../components/LogoMain';
import LinkUploadForm from '../../components/LinkUploadForm';

// import Loading from '../../elements/Loading';
import './uploadLinks.scss';

export default function ({ state }) {
    const { isFetching, linkData } = this.props;
    const { showDisable } = this.state;

    const getFilesFromEvent = (e) => new Promise((resolve) => {
        if (e.dataTransfer) {
            // Only resolve for drop events that have dataTransfer
            resolve(e.dataTransfer);
        } else if (e.target && e.target.files) {
            // Check if any files have webkitRelativePath (folder upload)
            const hasWebkitRelativePath = Array.from(e.target.files).some(file => file.webkitRelativePath);
            
            if (hasWebkitRelativePath) {
                const dataTransfer = new DataTransfer();
                Array.from(e.target.files).forEach(file => {
                    dataTransfer.items.add(file);
                });
                resolve(dataTransfer);
            } else {
                resolve(e.target.files);
            }
        }
    });

    return (
        <div className="linksContainer">
            <div className="linksHeader">
                <div className="logoContainer">
                    <LogoMain color="black" width="140px" />
                    <p className="linksUploader">Uploader</p>
                </div>
                <div className="linksUserContainer">
                    <p className="linksUser">Kavin</p>
                </div>
            </div>
            {!showDisable && (<Spin spinning={isFetching}>
                <Row>
                    <Col span={12} className="linksColContainer">
                        <div className="linksSectionContainer">
                            <p className="linksSectionTitle">
                                Upload Content
                            </p>
                            <p className="linksProductionName">{linkData.upload_link_title}</p>
                        </div>
                        <div className="linksSectionContainer">
                            <p className="linksSectionTitle">
                                Description
                            </p>
                            <p className="linksSectionContent">
                                {linkData.upload_link_description}
                            </p>
                        </div>
                        <LinkUploadForm isChecked={state} isFileQueue={this.isFileQueue} isLastFileQueue={this.isLastFileQueue} isLastFileExecuted={this.isLastFileExecuted} onCheckedChanged={this.onCheckedChanged} onSubmit={this.handleSubmit} />
                        <div className="footerContainer">
                            <b>Need Help?</b><br />
                            <a href="">Contact Image Approvals Support</a>
                        </div>
                    </Col>
                    <Col span={12}>
                        <Dropzone
                            onDrop={(e) => console.log('::::::::', e)}
                            accept="image/*,.zip,.rar,.7zip,.pdf,.dng,.cr2,.cr3,.crw,.arw,.nef,.raf,.psd,.3fr,.rwl,.raw,.xmp,.iiq"
                            inputContent="Click to upload files or drag and drop to upload folders"
                            getUploadParams={this.getUploadParams}
                            onChangeStatus={this.handleChangeStatus}
                            autoUpload={false}
                            getFilesFromEvent={getFilesFromEvent}
                            previewComponent={this.PreviewComponent}
                            LayoutComponent={this.Layout} />
                    </Col>
                </Row>
            </Spin>)}
            {showDisable && (
                <div className="linksColContainer">
                    <p>This upload link is no longer active. If you think you should be able to access it, please contact info@imageapprovals.com.</p>
                </div>
            )}
        </div>
    );
}
