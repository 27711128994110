import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Icon, Tabs, Tooltip, Empty, Modal, Button } from 'antd';
import moment from 'moment';
import { isEmpty, isNull } from 'underscore';

import * as globalActions from '../../core/global/globalActions';
import * as s3LinksActions from '../../core/s3Links/s3LinksActions';

import DOM from './uploadLinks';

import { fetchApi } from '../../core/utils/api';

const { TabPane } = Tabs;

class UploadLinks extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;
        this.signedUrl = null;
        this.fileInputRef = null;
        this.state = {
            isChecked: false,
            files: [],
            showModal: false,
            showDisable: false,
            isFileQueue: false,
            isLastFileQueue: false,
            isRetry: false,
            totalFiles: 0,
        };
        this.isLastFileExecuted = false;
        this.isLastFileExecutedError = false;
        this.isLastFileQueue = false;
    }


    componentWillMount() {
        const { params: { slug }, actions } = this.props;
        actions.verifyUserRequest({
            slug,
        });
    }

    componentDidMount() {
        if (this.fileInputRef) {
            this.fileInputRef.setAttribute('webkitdirectory', 'true');
        }
    }

    componentDidUpdate(prevProps) {
        const { linkData, isFetching } = this.props;
        if (linkData !== prevProps.linkData && !isFetching) {
            if (typeof linkData.upload_link_marked_complete === 'number' && linkData.upload_link_marked_complete === 1) {
                this.setState({
                    showDisable: true
                });
            }
        }
    }

    onCheckedChanged = (e) => {
        this.setState({
            isChecked: e.target.checked
        });
    }

    onStatusChanged = ({ file }) => {
        this.setState({
            files: [...this.state, file]
        });
    }

    onFileQueue = () => {
        this.isLastFileQueue = true;
        this.setState({
            isLastFileQueue: true
        });
    }

    showConfirmationModal = (file) => {
        this.setState({
            showModal: true
        });
        Modal.confirm({
            title: 'Some files were not uploaded.',
            content: 'Do you want to retry upload for these files?',
            onOk: () => {
                this.isRetry = true;
                this.checkFileUpload(file);
            },
            onCancel: () => {
                this.isLastFileQueue = true;
                setTimeout(() => {
                    this.isLastFileExecutedError = false;
                }, 500);
                this.setState({
                    showModal: false
                });
                this.setState({
                    isFileQueue: true
                });
            },
        });
    };

    showSuccessModal = () => {
        this.setState({
            showModal: true
        });
        Modal.confirm({
            title: 'Success.',
            onOk: () => {
                this.setState({
                    showModal: true
                });
            },
        });
    };

    Layout = ({ input, previews, dropzoneProps, files, extra: { maxFiles, isLastFile, onFiles, onDrop } }) => {
        const { linkData } = this.props;
        const totalFiles = files.length || 0;
        let totalFileSize = 0;
        const queuedFiles = files.filter(file => file.meta.status !== 'done');
        const queuedReadyFiles = files.filter(file => file.meta.status !== 'ready' && file.meta.status !== 'done');
        const readyFiles = files.filter(file => file.meta.status === 'ready');
        const completedFiles = files.filter(file => file.meta.status === 'done');

        const queued = queuedFiles.map((file, index) => {
            const { name, percent, status, fileUrl, path } = file.meta;
            if (isLastFile && !this.isLastFileExecutedError && (status === 'exception_upload' || status === 'error_upload') && !this.isRetry) {
                this.totalFiles = totalFiles;
                setTimeout(() => {
                    this.showConfirmationModal(file);
                }, 100);
                this.isLastFileExecutedError = true;
            }

            if (this.totalFiles !== totalFiles && this.isRetry) {
                this.isRetry = false;
                this.totalFiles = totalFiles;
            }

            totalFileSize += file.file.size || 0;
            let icon = <Icon type="clock-circle" style={{ color: 'grey' }} />;
            let statusLabel = `Pending (${percent}%)`;
            if (status === 'uploading') {
                icon = <Icon type="cloud-upload" style={{ color: 'yellow' }} />;
                statusLabel = `Uploading (${percent}%)`;
            } else if (status === 'ready') {
                icon = <Icon type="cloud-upload" style={{ color: 'green' }} />;
                statusLabel = (<span>
                    <a href="#" style={{ color: 'red' }} onClick={() => this.removeFileUpload(file)}>remove</a>
                </span>);
            } else if (status === 'exception_upload' || status === 'error_upload') {
                icon = <Icon type="stop" style={{ color: 'red' }} />;
                statusLabel = (<span>
                    <Tooltip title="Unable to upload file. Please try again.">
                        <Icon type="info-circle" theme="filled" />
                    </Tooltip>
                    {' Failed '}
                    (<a href="#" onClick={() => this.restartFileUpload(file)}>retry</a>)
                </span>);
            }

            const showPath = index === 0 || (queuedFiles[index - 1]?.meta?.path !== path);

            const pathHeader = path && showPath ? (
                <div className="uploader-preview_row">
                    <div className="uploader-preview_row__icon">
                        <Icon type="folder" style={{ color: '#1890ff' }} />
                    </div>
                    <div className="uploader-preview_row__name">{path}</div>
                </div>
            ) : null;

            const fileRow = (
                <div className="uploader-preview_row" key={`uploaded-item${index}`}>
                    <div className="uploader-preview_row__icon">{icon}</div>
                    <div className="uploader-preview_row__name">{name}</div>
                    <div className="uploader-preview_row__status">{statusLabel}</div>
                </div>
            );

            return (
                <div key={`upload-group-${index}`}>
                    {pathHeader}
                    {fileRow}
                </div>
            );
        });
        let completedFilesSize = 0;
        const completed = completedFiles.map((file, index) => {
            const { size, meta } = file;
            const { name, percent, status, fileUrl, path } = meta;

            completedFilesSize += file.file.size || 0;
            totalFileSize += file.file.size || 0;

            const showPath = index === 0 || (completedFiles[index - 1]?.meta?.path !== path);

            const pathHeader = path && showPath ? (
                <div className="uploader-preview_row">
                    <div className="uploader-preview_row__icon">
                        <Icon type="folder" style={{ color: '#1890ff' }} />
                    </div>
                    <div className="uploader-preview_row__name">{path}</div>
                </div>
            ) : null;

            const fileRow = (
                <div className="uploader-preview_row" key={`completed-item${index}`}>
                    <div className="uploader-preview_row__icon">
                        <Icon type="check" style={{ color: 'green' }} />
                    </div>
                    <div className="uploader-preview_row__name">{name}</div>
                    <div className="uploader-preview_row__status small-status">Uploaded: {moment().format('L')}</div>
                </div>
            );

            return (
                <div key={`upload-group-${index}`}>
                    {pathHeader}
                    {fileRow}
                </div>
            );
        });
        if (completedFiles.length > 0 || (completedFiles.length == 0 && totalFiles > 1)) {
            if ((completedFiles.length === totalFiles) && !this.isLastFileExecuted && (queuedFiles.length == 0)) {
                this.onFileQueue();
                this.isLastFileExecuted = true;
            }
        }
        const totalFilesSizeCal = totalFileSize > 0 ? totalFileSize : 1;
        const percentAll = Math.floor((completedFilesSize / totalFilesSizeCal) * 100);
        const totalUploadedFileSize = (linkData.uploaded_files_size || 0); // + completedFilesSize; // AWS S3 size takes time to update
        return (
            <div className="linksColContainer">
                <Tabs
                    type="card"
                    style={{
                        width: '90%',
                        alignSelf: 'center',
                        marginLeft: 24,
                        borderWidth: 0.5,
                        borderColor: '#E7E7E7',
                        minHeight: 300,
                        maxHeight: 350,
                        // overflowY: 'scroll',
                        marginTop: 24,
                        color: 'red'
                    }}>
                    <TabPane tab={`Queued / Uploading (${previews.filter(preview => preview.props.meta.status !== 'done').length})`} key="1">
                        {queued.length > 0 ? queued : <Empty description="No files queued" />}
                    </TabPane>
                    <TabPane tab={`Completed (${previews.filter(preview => preview.props.meta.status === 'done').length})`} key="2">
                        {completed.length > 0 ? completed : <Empty description="No files completed" />}
                    </TabPane>
                </Tabs>
                <div {...dropzoneProps}>
                    {files.length < maxFiles && input}
                    {files.length > 0 && (
                        <div style={{ position: 'absolute', margin: 20, left: 100 }}>
                            <Button disabled={queued.length === 0} onClick={this.handleSubmit} type="red" style={{ borderRadius: 5, height: 32, fontSize: 12 }}>Upload Queued Files</Button>
                        </div>)}
                </div>
                <div className="upload-stats">
                    <div style={{ margin: 20, display: 'flex', justifyContent: 'flex-end' }}>

                        <p className="or-text">OR</p>
                        <Button
                            className="upload-folder-button"
                            onClick={() => {
                                if (this.fileInputRef) {
                                    this.fileInputRef.click();
                                }
                            }}>
                            Upload Folder
                        </Button>
                        <input
                            type="file"
                            ref={(ref) => { this.fileInputRef = ref; }}
                            multiple
                            webkitdirectory=""
                            onChange={(e) => onDrop(e)}
                            style={{ display: 'none' }} />
                    </div>
                    <div className="title-main">
                        {completedFiles.length} of {totalFiles} file uploaded
                        ({this.bytesToSize(completedFilesSize)} of {this.bytesToSize(totalFileSize)} - {percentAll}%)
                    </div>
                    <div>{this.bytesToSize(totalUploadedFileSize)} of {linkData.max_upload_size} GB Upload Limit Used</div>
                </div>
            </div>
        );
    };

    PreviewComponent = ({ meta }) => {
        const { name, percent, status, fileUrl } = meta;
        return (<div className="uploader-preview_row">
            <div className="uploader-preview_row__name">{name}</div>
            <div className="uploader-preview_row__status">{status}</div>
            <div className="uploader-preview_row__percent">{percent}</div>
        </div>);
    };

    getUploadParams = async ({ file, meta }) => {
        const { linkData } = this.props;
        const { preSignedUrl } = linkData;

        // if (!isNull(preSignedUrl)) {
        //     return { url: preSignedUrl, method: 'PUT', headers: { 'Content-Type': file.type } };
        // }
        const { data } = await fetchApi({
            method: 'post',
            url: '/upload-links/get-signed-url',
            headers: { 'Content-Type': file.type },
            body: {
                fileName: meta.name,
                fileType: file.type,
                link: linkData.upload_link_slug_hash,
                path: meta.path
            },
        });

        const { uploadUrl } = data;
        this.signedUrl = uploadUrl;
        return { url: uploadUrl, method: 'PUT', headers: { 'Content-Type': file.type } };
    };

    // called every time a file's `status` changes
    handleChangeStatus = (files, status) => {
        if (status === 'ready') {
            this.setState((prevState) => ({
                files: [...prevState.files, files],
            }));
        } else if (status === 'removed') {
            this.setState((prevState) => ({
                files: prevState.files.filter(file => file !== files),
            }));
        }
    };

    // receives array of files that are done uploading when submit button is clicked
    handleSubmit = () => {
        this.state.files.map(file => this.restartFileUpload(file));
        this.setState({
            files: []
        });
    };

    bytesToSize = (bytes) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return 0;
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
        if (i === 0) return `${bytes} ${sizes[i]}`;
        return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
    };

    restartFileUpload = (file) => {
        file.restart();
    }

    removeFileUpload = (file) => {
        file.remove();
    }

    checkFileUpload = (file) => {
        file.check();
        this.setState({
            showModal: false
        });
        setTimeout(() => {
            this.isLastFileExecutedError = false;
        }, 500);
    }


    render() {
        return this.view({ global, state: this.state });
    }
}

UploadLinks.defaultProps = {
    params: {},
    actions: {},
    user: {},
};

UploadLinks.propTypes = {
    params: PropTypes.object,
    actions: PropTypes.object,
    user: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        isFetching: state.s3Links.isFetching,
        linkData: state.s3Links.linkData,
        user: state.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...globalActions,
                ...s3LinksActions,
            },
            dispatch,
        ),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UploadLinks);
